<template>
  <div class="d-flex">
    <div class="mr-1 mt-1">
      <p>{{ $t('Rows per page') }}:</p>
    </div>
    <div class="mr-2">
      <b-form-select
        id="rowsPerPage"
        v-model="pagination.perPage"
        @change="onOffsetChange"
      >
        <b-form-select-option
          v-if="this.referenceTable === 'contractDefinitionTable'"
          value="5"
          >5</b-form-select-option
        >
        <b-form-select-option value="20">20</b-form-select-option>
        <b-form-select-option value="50">50</b-form-select-option>
        <b-form-select-option value="100">100</b-form-select-option>
      </b-form-select>
    </div>
    <div v-if="$i18n.locale == 'hi'" class="mt-1">
      <b-spinner v-if="isPaginationBusy" small variant="primary" />
      <span v-else>{{ totalRows || 0 }}</span>
      {{ $t('from') }} {{ paginationFrom }}-{{ paginationTo }}
    </div>
    <div v-else-if="$i18n.locale == 'en'" class="mt-1">
      {{ paginationFrom }}-{{ paginationTo }} {{ $t('of') }}
      <b-spinner v-if="isPaginationBusy" small variant="primary" />
      <span v-else>{{ totalRows || 0 }}</span>
    </div>
    <div class="ml-auto mt-1">
      <b-pagination
        size="sm"
        class="mb-0 float-right"
        :aria-controls="referenceTable"
        :prev-text="$t('Previous')"
        :next-text="$t('Next')"
        hide-goto-end-buttons
        v-model="pagination.currentPage"
        :total-rows="totalRows"
        :per-page="pagination.perPage"
        @change="pageChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    isPaginationBusy: { type: Boolean },
    paginationData: { type: Object },
    referenceTable: { type: String },
    totalRows: { type: Number }
  },
  emits: ['pageChange', 'onOffsetChange'],
  data() {
    return {
      pagination: {
        currentPage: 1,
        perPage: 20
      }
    }
  },
  watch: {
    paginationData: function () {
      this.pagination = this.paginationData
    },
    totalRows: function () {
      this.pagination = this.paginationData
    }
  },
  computed: {
    paginationFrom() {
      if (this.totalRows === 0) return 0
      return (this.pagination.currentPage - 1) * this.pagination.perPage + 1
    },
    paginationTo() {
      const end = this.pagination.currentPage * this.pagination.perPage
      return this.totalRows < end ? this.totalRows : end
    }
  },
  methods: {
    pageChange(newPage) {
      this.$emit('pageChange', newPage)
    },
    onOffsetChange(perPage) {
      this.pagination.currentPage = 1
      this.$emit('onOffsetChange', perPage)
    }
  }
}
</script>
